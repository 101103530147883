import { BlogPostPageQuery, CdnImageDataFragment } from 'gatsby/graphqlTypes';

import { AppPageProps } from 'gatsby/types';
import { Container } from 'components/atoms/layout/Container';
import PageContentLayout from 'components/layouts/PageContentLayout';
import React from 'react';
import SlateContent from 'components/molecules/cms/SlateContent';
import StaticPageHeader from 'components/molecules/banners/StaticPageHeader';
import { graphql } from 'gatsby';

export { Head } from 'components/atoms/Head';

export const query = graphql`
  query BlogPostPage($strapiId: Int!) {
    c: strapiBlogPost(strapiId: { eq: $strapiId }) {
      title
      summary
      rt: contentReadingTime
      c: contentSlate {
        ...SlateContent
      }
      publishedAt
      subtitle
      updatedAt
      bannerMedia {
        ...CdnImageData
      }
    }
  }
`;

export type Props = AppPageProps<BlogPostPageQuery>;

const BlogPost: React.FC<Props> = ({ data, pageContext }) => {
  const {
    title,
    bannerMedia,
    subtitle,
    c: contentSlate,
    rt,
    updatedAt,
    publishedAt
  } = data.c as NonNullable<BlogPostPageQuery['c']>;

  const publishedDate = publishedAt && new Date(publishedAt);
  const updatedDate = updatedAt && new Date(updatedAt);

  const showUpdatedDate =
    publishedDate &&
    updatedDate &&
    (publishedDate.getFullYear() !== updatedDate.getFullYear() ||
      publishedDate.getMonth() !== updatedDate.getMonth() ||
      publishedDate.getDay() !== updatedDate.getDay());

  const publishedDateStr =
    publishedDate &&
    new Intl.DateTimeFormat('en-GB', {
      dateStyle: 'long'
    }).format(publishedDate);

  const lastUpdatedDateStr =
    updatedDate &&
    new Intl.DateTimeFormat('en-GB', {
      dateStyle: 'long'
    }).format(updatedDate);

  return (
    <PageContentLayout footerNavigation={pageContext.footerNavigation}>
      <article>
        <StaticPageHeader
          large
          title={title}
          image={bannerMedia as CdnImageDataFragment}
          subtitle={subtitle}
          subtitle2={rt}
          subtitle3={`${publishedDateStr}${
            showUpdatedDate ? ` (Updated: ${lastUpdatedDateStr})` : ''
          }`}
        />
        <Container className="my-rhythm5 flex justify-center">
          <SlateContent
            content={contentSlate}
            measure="measure"
            headingMeasure="measure-narrow"
          />
        </Container>
      </article>
    </PageContentLayout>
  );
};

export default BlogPost;
